<template>
  <v-app style="background-color: #f5f5f5 !important;">
      <v-app-bar
              flat
              app
              color="primary">
          <span class="white--text text-lg-h5 text-md-h5 ">
              Project: Build Clara's Knowledge Base
          </span>
          <v-spacer></v-spacer>
          <span
                  v-if="showUserInfo"
                  class="white--text mr-4 text-h7 " >
              Hello, {{userName}}
          </span>
          <v-icon
                  @click="$store.commit('showHelpDialog', true)"
                  dark
                  large>
              mdi-help-circle
          </v-icon>
      </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
      <v-footer class="main">
          <v-col
                  cols="12"
                  class="text-center grey--text">
              {{new Date().getFullYear()}} — <strong>Project: Clara Court Orientation </strong>
          </v-col>
      </v-footer>
    <login></login>
      <help></help>
      <snackBar></snackBar>

  </v-app>
</template>

<script>
import login from "@/components/login";
import help from '@/components/help';
import snackBar from '@/components/snackBar.vue';
import {mapGetters} from 'vuex';

export default {
  name: 'App',

  data: () => ({
    showUserInfo: false,
    userName: ''
  }),
  components:{
    login,
    help,
    snackBar
  },
  computed:{
    ...mapGetters(['getUserInfo'])
  },
  watch:{
    getUserInfo(newVal){
      this.showUserInfo = true;
      this.userName = newVal.name;
    }
  }
};
</script>
<style>
html, body {
  margin: 0;

}

body::-webkit-scrollbar {
  display: none;
}
</style>
