<template>
    <v-row>
        <v-col
                cols="12"
                align="center"
                class="mt-4">
            <v-card
                    class="pa-5"
                    width="85%"
                    elevation="0"
                    flat>
                <v-form
                        lazy-validation
                        ref="qna">
                    <v-row>
                        <v-col lg="4" md="4" cols="12">
                            <v-combobox
                                    label="Question"
                                    v-model="question"
                                    outlined
                                    autocomplete="off"
                                    :items="getQuestions"
                                    :rules="textRules"
                                    append-icon=" ">
                            </v-combobox>
                        </v-col>
                        <v-col lg="4" md="4" cols="12">
                            <v-textarea
                                    outlined
                                    auto-grow
                                    rows="1"
                                    row-height="25"
                                    label="Answer"
                                    v-model="answer"
                                    :rules="textRules"
                                    autocomplete="off">
                            </v-textarea>
                        </v-col>
                        <v-col lg="4" md="4" cols="12">
                            <v-textarea
                                    outlined
                                    auto-grow
                                    rows="1"
                                    row-height="25"
                                    label="Comment"
                                    v-model="comment"
                                    @keyup.enter="saveData()"
                                    autocomplete="off">
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <div class="d-flex justify-end">
                        <v-btn
                                class="mr-2"
                                color="primary"
                                outlined
                                elevation="0"
                                @click="$refs.qna.reset()">
                            Reset
                        </v-btn>
                        <v-btn
                                color="primary"
                                elevation="0"
                                @click="saveData()">
                            Submit
                        </v-btn>
                    </div>

                </v-form>
            </v-card>
        </v-col>
        <v-col
                cols="12"
                align="center"
                justify="center">
            <v-card
                    class="px-5"
                    width="85%"
                    elevation="0">
                <v-card-actions>
                    <v-switch
                            dense
                            hide-details
                            v-model="showMyResponse"
                            label="Only show my responses."
                    ></v-switch>

                </v-card-actions>
                <v-data-table
                        :headers="addedQnaHeaders"
                        :items="qnaList"
                        :items-per-page="5"
                        :search="search"
                >
                    <template v-slot:item.question="{item}">
                        {{item.question | trimLength}}
                    </template>
                    <template v-slot:item.answer="{item}">
                        {{item.answer | trimLength}}
                    </template>
                    <template v-slot:item.comment="{item}">
                        {{item.comment | trimLength}}
                    </template>
                    <template v-slot:item.user="{item}">
                        {{item.user | trimLength}}
                    </template>
                    <template v-slot:item.dateTime="{item}">
                        {{item.dateTime | formatDate}}
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import db from '@/firebase/index.js';
import {mapGetters} from 'vuex';
import moment from 'moment';

export default {
  name: 'Home',
  data() {
    return {
      search:'',
      showMyResponse: false,
      question: '',
      answer: '',
      comment: '',
      addedQnaHeaders: [
        {text: 'Questions', align: 'start', value: 'question'},
        {text: 'Answers', value: 'answer'},
        {text: 'Comments', value: 'comment'},
        {text: 'Username', value: 'user'},
        {text: 'Date Added', value: 'dateTime'}
      ],
      textRules: [
        v => !!v || 'Cannot be empty.',
      ]
    }
  },
  computed:{
    ...mapGetters(['getUserInfo','getQnaList']),
    getQuestions(){
      let questions = []
      for(let i = 0; i < this.getQnaList.length; i++){
        questions.push(this.getQnaList[i].data().question)
      }
      return questions
    },
    qnaList(){
      let list=[];
      this.getQnaList.forEach(item => list.push(item.data()));
      return list;
    }
  },
  watch: {
    showMyResponse(newVal){
      if(newVal){
        this.search = this.getUserInfo.name
      }
      else{
        this.search='';
      }

    }
  },
  methods: {
    saveData() {
      if(this.$refs.qna.validate()){
        const input = {
          question: this.question,
          answer: this.answer,
          comment: this.comment,
          user: this.getUserInfo.name,
          courtName: this.getUserInfo.courtHouse,
          dateTime:  new Date()
        }
        db.collection('qnaPair').add(input)
          .then(() => {
          this.$store.dispatch('showSnackBar', {
            text: 'Q&A saved successfully',
            color: 'success'
          })
          this.$refs.qna.reset()
        })
          .catch(error => {
            this.$store.dispatch('showSnackBar', {
              text: error.message,
              color: 'error'
            })
          })
      }
    }
  },

  mounted() {
    this.$store.commit('updateOpenDialog', true)
  },
  filters:{
    trimLength(val){
      if(!val) {return '-'}
      if(val.length < 90) {return val}
      return `${val.substring(0,70)}...`
    },
    formatDate(val){
      if(!val) {return '-'}
      let date = val.toDate();
      return moment(date).fromNow();
    }
  }
}
</script>
