<template>
  <v-dialog
      v-model="getOpenDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      width="40%">
    <v-card class="pa-5">
        <v-card-title class="d-flex">
            <v-spacer></v-spacer>
            <v-img
                    class="pb-4"
                    src="@/assets/logo.png"
                    contain width="50%"></v-img>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
      <v-card-text align="center">
        <v-form lazy-validation ref="userInfo">
          <v-text-field
              label="Name"
              v-model="userName"
              :rules="nameRules"
              autocomplete="off"
              prepend-icon="mdi-account">
          </v-text-field>
          <v-select
              @focus="($event) => {$event.target.click()}"
              v-model="courtName"
              :items="courtList"
              :rules="courtRules"
              label="Court Name"
              autocomplete="off"
              prepend-icon="mdi-gavel"
              required
          ></v-select>
            <div class="d-flex justify-end">
                <v-btn
                        color="primary"
                        elevation="0"
                        tile
                        @click="submitInfo()">
                    Submit
                </v-btn>
            </div>
        </v-form>
      </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
            <span>Please enter your name and select a courthouse to get started.
                You’ll be directed to a page where you can add Questions and Answers
                to Clara’s knowledge base.</span>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "login",
  computed: {
    ...mapGetters(['getOpenDialog'])
  },
  data() {
    return {
      userName: '',
      courtName: '',
      courtList: [
        'Santa Fe County First Judicial District Court',
        'Taos County Eighth Judicial District Court',
        'Chaves County Fifth District Court (Roswell)',
        'Bernalillo County Second Judicial District Court',
        'NM Supreme Court (Test Unit)'],
      nameRules: [
        v => !!v || 'Name is required',
      ],
      courtRules: [
        v => !!v || 'Court Name is required',
      ],
    }
  },
  methods: {
    submitInfo() {
      if(this.$refs.userInfo.validate()){
        //To store user details
        const userData = {
          name: this.userName,
          courtHouse: this.courtName
        }
        this.$store.commit('updateUserInfo', userData)
        this.$store.dispatch('getAllQnA').then(() =>{
          this.$store.commit('updateOpenDialog', false)
          this.$store.commit('showHelpDialog', true)

          // clearing inserted Data
          this.$refs.userInfo.reset()
        })
      }

    }
  }
}
</script>

<style scoped>

</style>
