<template>
<v-row>
    <v-col
                cols="12"
                align="center"
                justify="center">
            <v-card
                    class="px-5"
                    width="85%"
                    elevation="0">                
                <v-data-table
                        :headers="addedQnaHeaders"
                        :items="qnaList"
                        :items-per-page="15"
                        :search="search"
                >
                    <template v-slot:item.question="{item}">
                        {{item.question | trimLength}}
                    </template>
                    <template v-slot:item.answer="{item}">
                        {{item.answer | trimLength}}
                    </template>
                    <template v-slot:item.comment="{item}">
                        {{item.comment | trimLength}}
                    </template>
                    <template v-slot:item.user="{item}">
                        {{item.user | trimLength}}
                    </template>
                    <template v-slot:item.courtName="{item}">
                        {{item.courtName | trimLength}}
                    </template>
                    <template v-slot:item.dateTime="{item}">
                        {{item.dateTime | formatDate}}
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
</v-row>
    
</template>

<script>

import {mapGetters} from 'vuex';
import moment from 'moment';

export default {
    
    name: 'all',
    data(){
        return{
            search:'',
            addedQnaHeaders: [
            {text: 'QUESTIONS', align: 'start', value: 'question'},
            {text: 'ANSWERS', value: 'answer'},
            {text: 'COMMENTS', value: 'comment'},
            {text: 'USERNAME', value: 'user'},
            {text: 'COURTNAME', value: 'courtName'},
            {text: 'DATE ADDED', value: 'dateTime'}
        ],
        }
    },
    computed:{
        ...mapGetters(['getAllQna']),
        qnaList(){
            let list=[];
            this.getAllQna.forEach(item => list.push(item.data()));            
            return list;
            }
    },
    mounted(){
        this.$store.dispatch('getQnA')
    },
    filters:{
    trimLength(val){
      if(!val) {return '-'}
      if(val.length < 90) {return val}
      return `${val.substring(0,70)}...`
    },
    formatDate(val){
      if(!val) {return '-'}
      let date = val.toDate();
      return moment(date).fromNow();
    }
  }

    
}
</script>
