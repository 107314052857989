import firebase from 'firebase/app';
import 'firebase/firestore';

let firebaseConfig = {
    apiKey: "AIzaSyBxpVFV6btWG398NXl1lf77rHurAhw-YWs",
    authDomain: "questionnaire-57679.firebaseapp.com",
    projectId: "questionnaire-57679",
    storageBucket: "questionnaire-57679.appspot.com",
    messagingSenderId: "652414090068",
    appId: "1:652414090068:web:de6da97f6bb660d5d9a6c1",
    measurementId: "G-TM7R772ZV1"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export default db;
