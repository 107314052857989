var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-card',{staticClass:"px-5",attrs:{"width":"85%","elevation":"0"}},[_c('v-data-table',{attrs:{"headers":_vm.addedQnaHeaders,"items":_vm.qnaList,"items-per-page":15,"search":_vm.search},scopedSlots:_vm._u([{key:"item.question",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trimLength")(item.question))+" ")]}},{key:"item.answer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trimLength")(item.answer))+" ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trimLength")(item.comment))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trimLength")(item.user))+" ")]}},{key:"item.courtName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trimLength")(item.courtName))+" ")]}},{key:"item.dateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dateTime))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }